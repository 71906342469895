import React, { useEffect } from 'react';
import loadable from '@loadable/component';

import '/client/app/styles/text.scss';
import AddContentButton from '/client/app/components/addContentButton/addContentButtonContainer';
import CollapsingSidebarLayout from '/client/app/components/layouts/collapsingSidebarLayout/collapsingSidebarLayout';
import MultiSubSidebarContainer from '/client/app/components/communitySidebar/multiSubSidebarContainer';

const FeedContainer = loadable(() => import('/client/app/components/feed/feedContainer'));

export default function MainFeedView() {
	useEffect(() => {
		document.title = 'pfyt - main feed';

		return function cleanup() {
			document.title = 'pfyt';
		};
	});

	return (
		<>
			<div className="mainTitle">main feed</div>
			<CollapsingSidebarLayout>
				<FeedContainer communityNames={['news', 'ask', 'pics']} />
				<MultiSubSidebarContainer subNames={['news', 'ask', 'pics']} />
			</CollapsingSidebarLayout>
			<AddContentButton />
		</>
	);
}
